import { TBaseSVG } from "../../models";

export default ({
    className,
    color,
    onClick,
    title,
    height,
    width,
    viewBox,
}: TBaseSVG) => (
    <svg
        width={width ?? "35"}
        height={height ?? "35"}
        viewBox={viewBox ?? `0 0 35 35`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        className={className}
    >
        <title>{title}</title>
        <path
            d="M14.9479 2.91663C15.225 2.91663 15.4729 3.07704 15.5896 3.29579L18.8271 9.07079L18.9583 9.47913L18.8271 9.88746L15.5896 15.6625C15.4729 15.8812 15.225 16.0416 14.9479 16.0416H8.38542C8.10833 16.0416 7.86042 15.8812 7.74375 15.6625L4.50625 9.88746L4.375 9.47913L4.50625 9.07079L7.74375 3.29579C7.86042 3.07704 8.10833 2.91663 8.38542 2.91663H14.9479ZM14.9479 18.9583C15.225 18.9583 15.4729 19.1187 15.5896 19.3375L18.8271 25.1125L18.9583 25.5208L18.8271 25.9291L15.5896 31.7041C15.4729 31.9229 15.225 32.0833 14.9479 32.0833H8.38542C8.10833 32.0833 7.86042 31.9229 7.74375 31.7041L4.50625 25.9291L4.375 25.5208L4.50625 25.1125L7.74375 19.3375C7.86042 19.1187 8.10833 18.9583 8.38542 18.9583H14.9479ZM28.4375 10.9375C28.7146 10.9375 28.9625 11.0979 29.0792 11.3166L32.3167 17.0916L32.4479 17.5L32.3167 17.9083L29.0792 23.6833C28.9625 23.902 28.7146 24.0625 28.4375 24.0625H21.875C21.5979 24.0625 21.35 23.902 21.2333 23.6833L17.9958 17.9083L17.8646 17.5L17.9958 17.0916L21.2333 11.3166C21.35 11.0979 21.5979 10.9375 21.875 10.9375H28.4375Z"
            fill={color ?? "black"}
            // fill-opacity="0.56"
            stroke={color}
            color={color}
        />
    </svg>
);
