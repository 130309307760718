import { WarningAmberOutlined } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import { FotoUser } from "../../../../../../assets";
import Tooltip from "../../../../../../components/atoms/tooltip/tooltip";
import { useReadBudgetById } from "../../../../../../hooks/react-query/use-read-budget-by-id";
import { useReadBudgetsStatus } from "../../../../../../hooks/react-query/use-read-budgets-status";
import {
    arquiveBudget,
    deleteStatus,
    unarquiveBudget,
    updateBudget,
} from "../../../../../../services/budget";
import { formatISOToDate } from "../../../../../../utils";
import { formatCurrency } from "../../../../../organization/project/tab-costs/utils";
import { SendNotificationDialog } from "./notification-responsibles-dialog";
import OverviewTable from "./overview-table";
import { StatusOverviewDialog } from "./status-overview-dialog";
import StatusSelect, { IStatusOption } from "./status-overview-select";

function OverviewTab() {
    const [isOpenSendNotificationDialog, setIsOpenSendNotificationDialog] =
        useState(false);
    const [isOpenStatusDialog, setIsOpenStatusDialog] = useState(false);
    const [selectStatusToEdit, setSelectStatusToEdit] =
        useState<IStatusOption>();
    const { budgetId } = useParams();
    const id = budgetId || "";

    const { data: dataBudgetById, refetch } = useReadBudgetById({
        budgetId: id,
    });

    const { data: budgetsStatus, refetch: refetchBudgetStatus } =
        useReadBudgetsStatus({
            length: 100,
            page: 1,
        });

    const statusOptions =
        budgetsStatus &&
        budgetsStatus?.data?.map((status) => ({
            label: status.name,
            id: status.id_e,
            color: status.color,
        }));

    const handleArquiveBudget = async () => {
        await arquiveBudget({ budgetId: id });
        await refetch();
    };

    const handleUnarquiveBudget = async () => {
        await unarquiveBudget({ budgetId: id });
        await refetch();
    };

    const handleDeleteStatus = useCallback(
        async (statusId: string) => {
            await deleteStatus({ statusId })
                .then(() => toast.success("Status excluido com sucesso!"))
                .catch(() => toast.error("Erro ao excluir status!"));
            await refetchBudgetStatus();
        },
        [budgetsStatus, dataBudgetById]
    );

    const handleStatusDialog = useCallback(
        (data?: IStatusOption) => {
            setIsOpenStatusDialog((prev) => !prev);
            setSelectStatusToEdit(data ?? undefined);
        },
        [budgetsStatus, isOpenStatusDialog, setIsOpenStatusDialog]
    );

    const handleSendNotificationDialog = useCallback(() => {
        setIsOpenSendNotificationDialog((prev) => !prev);
    }, [isOpenSendNotificationDialog, setIsOpenSendNotificationDialog]);

    const handleUpdateStatus = useCallback(
        async (statusId: string) => {
            const bodyParams = {
                statusId,
            };
            await updateBudget(id, bodyParams);
            await refetch();
        },
        [budgetsStatus, dataBudgetById]
    );

    const profiles = dataBudgetById?.BudgetProfiles;
    const total = dataBudgetById?.totalPrice ?? 0;
    const isArquived = dataBudgetById?.isArchived;
    const budgetsProfiles = dataBudgetById?.BudgetProfiles;
    const filterProfileThatHaveResponsibles = budgetsProfiles?.filter(
        (profile) => profile.responsible !== null
    );
    const options = filterProfileThatHaveResponsibles
        ? filterProfileThatHaveResponsibles?.map((budgetProfile) => ({
              label: budgetProfile.Profile?.name,
              value: budgetProfile.id_e,
          }))
        : [];

    const hableSendResponsible =
        filterProfileThatHaveResponsibles &&
        filterProfileThatHaveResponsibles?.length > 0;

    const profilesWithResponsible = budgetsProfiles?.filter(
        (profile) => profile?.responsible?.id_e
    );

    const haveDraftResponsible = profilesWithResponsible?.some(
        (profile) => profile?.hoursEstimationStatus === "draft"
    );
    return (
        <>
            <Stack>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: "1.5rem",
                    }}
                >
                    <Stack
                        sx={{
                            gap: "0.5rem",
                        }}
                    >
                        <Typography variant="h5">Visão Geral</Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: "rgba(62, 55, 78, 0.60)",
                                fontFamily: "Noto Sans",
                                fontSize: "0.75rem",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "16px",
                                letterSpacing: "0.06rem",
                                textTransform: "uppercase",
                            }}
                        >
                            CRIADO EM{" "}
                            {dataBudgetById?.created_at &&
                                formatISOToDate(dataBudgetById?.created_at)}
                        </Typography>
                    </Stack>
                    {/* <Button
        variant="text"
        sx={{
            gap: "0.5rem",
        }}
    >
        <Download
            sx={{
                color: theme.primary.main,
                width: "1.5rem",
                height: "1.5rem",
            }}
        />{" "}
        Baixar orçamento
    </Button> */}
                </Box>
                <Stack
                    sx={{
                        p: "1.5rem",
                        borderTop: "1px solid rgba(0, 0, 0, 0.23)",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
                        gap: "1.5rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Box>
                            <StatusSelect
                                handleStatusDialog={handleStatusDialog}
                                statusOptions={statusOptions}
                                status={dataBudgetById?.Status}
                                handleDeleteStatus={handleDeleteStatus}
                                handleUpdateStatus={handleUpdateStatus}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1.5rem",
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={handleSendNotificationDialog}
                                disabled={!hableSendResponsible}
                            >
                                Reenviar para Responsáveis
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#2E7D32",
                                }}
                            >
                                Iniciar PROJETO
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "0.5rem",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#2E7D32",
                                fontFamily: "Noto Sans",
                                fontSize: "0.75rem",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "1rem",
                                letterSpacing: "0.06rem",
                                textTransform: "uppercase",
                            }}
                        >
                            VALOR FINAL:
                        </Typography>
                        <Typography
                            sx={{
                                color: "rgba(62, 55, 78, 0.87)",
                                fontFamily: "Noto Sans",
                                fontSize: "0.75rem",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "1rem",
                                letterSpacing: "0.06rem",
                                textTransform: "uppercase",
                            }}
                        >
                            {formatCurrency(String(total.toFixed(2)))}
                        </Typography>
                    </Box>
                    <Stack
                        sx={{
                            gap: "1rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1.5rem",
                            }}
                        >
                            {profiles &&
                                profiles?.map((profile) => {
                                    if (profile?.responsible) {
                                        return (
                                            <Tooltip
                                                key={profile?.id_e}
                                                side="bottom"
                                                backgroundColor="rgba(97, 97, 97, 0.9)"
                                                content={
                                                    <span
                                                        style={{
                                                            color: "#FFF",
                                                            fontFamily:
                                                                "Noto Sans",
                                                            fontSize:
                                                                "0.875rem",
                                                            fontStyle: "normal",
                                                            fontWeight: 400,
                                                            lineHeight:
                                                                "1.25rem",
                                                            letterSpacing:
                                                                "0.0088rem",
                                                        }}
                                                    >
                                                        {
                                                            profile.responsible
                                                                ?.name
                                                        }
                                                    </span>
                                                }
                                            >
                                                <Box
                                                    sx={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: "2.5rem",
                                                            height: "2.5rem",
                                                            borderRadius: "50%",
                                                            backgroundImage: `url(${
                                                                profile
                                                                    .responsible
                                                                    ?.avatar ??
                                                                FotoUser
                                                            })`,
                                                            backgroundSize:
                                                                "cover",
                                                            backgroundPosition:
                                                                "center",
                                                            border:
                                                                profile.hoursEstimationStatus ===
                                                                "sent"
                                                                    ? "1px solid #2E7D32"
                                                                    : "unset",
                                                        }}
                                                    />
                                                    {profile.hoursEstimationStatus ===
                                                        "sent" && (
                                                        <div
                                                            style={{
                                                                position:
                                                                    "absolute",
                                                                bottom: "0px",
                                                                right: "0px",
                                                                width: "0.5rem",
                                                                height: "0.5rem",
                                                                borderRadius:
                                                                    "50%",
                                                                backgroundColor:
                                                                    "#2E7D32",
                                                                border: "2px solid #fff",
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            </Tooltip>
                                        );
                                    }
                                    return null;
                                })}
                        </Box>
                        {!haveDraftResponsible && (
                            <Typography variant="body1">
                                Todos os responsáveis concluíram as estimativas.
                            </Typography>
                        )}
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        p: "1.5rem",
                        gap: "1.5rem",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Noto Sans",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "16px",
                            letterSpacing: "0.96px",
                            textTransform: "uppercase",
                        }}
                    >
                        Resumo
                    </Typography>
                    <OverviewTable dataBudgetById={dataBudgetById} />
                </Stack>
                {haveDraftResponsible && (
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            border: "1px solid #EF6C00",
                            maxWidth: "29.75rem",
                            borderRadius: "0.25rem",
                            px: "1rem",
                            py: "0.5rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                gap: "0.75rem",
                            }}
                        >
                            <WarningAmberOutlined
                                sx={{
                                    color: "rgba(239, 108, 0, 1)",
                                }}
                            />
                            <Stack
                                sx={{
                                    color: "rgba(102, 60, 0, 1)",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "#663C00",
                                        fontFamily: "Work Sans",
                                        fontSize: " 16px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "150%",
                                        letterSpacing: "0.15px",
                                    }}
                                >
                                    Estimativa Incompleta
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "#663C00",
                                        fontFamily: "Work Sans",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "143%",
                                        letterSpacing: "0.17px",
                                    }}
                                >
                                    RESPONSÁVEIS AINDA NÃO ESTIMARAM HORAS EM
                                    ALGUM PERFIL DE ATIVIDADE{" "}
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                )}
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                    }}
                >
                    {isArquived ? (
                        <Button
                            sx={{
                                width: "auto",
                            }}
                            color="success"
                            variant="outlined"
                            onClick={handleUnarquiveBudget}
                        >
                            DESARQUIVAR orçamento
                        </Button>
                    ) : (
                        <Button
                            onClick={handleArquiveBudget}
                            sx={{
                                width: "auto",
                            }}
                            color="error"
                            variant="outlined"
                        >
                            ARQUIVAR orçamento
                        </Button>
                    )}
                </Box>
            </Stack>
            {isOpenStatusDialog && (
                <StatusOverviewDialog
                    handleClose={handleStatusDialog}
                    open={isOpenStatusDialog}
                    refetchBudgetStatus={refetchBudgetStatus}
                    selectStatusToEdit={selectStatusToEdit}
                />
            )}
            {isOpenSendNotificationDialog && (
                <SendNotificationDialog
                    budgetId={id}
                    open={isOpenSendNotificationDialog}
                    handleClose={handleSendNotificationDialog}
                    options={options}
                    refetch={refetch}
                />
            )}
        </>
    );
}

export default OverviewTab;
