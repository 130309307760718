import {
    Box,
    InputAdornment,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { Controller, Control, FieldValues, Path } from "react-hook-form";

type CustomTextFieldProps<T extends FieldValues> = TextFieldProps & {
    name: Path<T>;
    control: Control<T>;
    required?: boolean;
    maxCharacters?: number;
    formatValue?: (value: string) => string;
    isCurrency?: boolean;
    endAdornment?: ReactNode;
    startAdornment?: ReactNode;
};

const CustomTextField = <T extends FieldValues>({
    name,
    control,
    required = false,
    maxCharacters,
    formatValue,
    isCurrency = false,
    endAdornment,
    fullWidth,
    startAdornment,
    sx,

    ...textFieldProps
}: CustomTextFieldProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: required ? "Campo obrigatório" : false,
            }}
            render={({ field, fieldState: { error } }) => (
                <Box
                    sx={{
                        position: "relative",
                        width: fullWidth ? "100%" : "auto",
                    }}
                >
                    <TextField
                        {...field}
                        {...textFieldProps}
                        error={!!error}
                        inputProps={{
                            maxLength: maxCharacters,
                        }}
                        InputProps={{
                            startAdornment: startAdornment ? (
                                <InputAdornment position="start">
                                    {startAdornment}
                                </InputAdornment>
                            ) : null,
                            endAdornment: endAdornment || null,
                        }}
                        onChange={(e) => {
                            let rawValue = e.target.value;
                            rawValue = formatValue
                                ? formatValue(rawValue)
                                : rawValue;

                            const formattedValue = isCurrency
                                ? `R$ ${rawValue.replace(/[^\d,]/g, "")}`
                                : rawValue;

                            field.onChange(formattedValue);
                        }}
                        value={
                            isCurrency && field.value
                                ? `R$ ${field.value.replace(/[^\d,]/g, "")}`
                                : field.value
                        }
                        sx={{
                            width: fullWidth ? "100%" : "auto",
                            ...sx,
                        }}
                    />
                    {error && (
                        <Typography
                            variant="subtitle1"
                            sx={{
                                position: "absolute",
                                bottom: "-1.5rem",
                                fontSize: "0.75rem",
                                marginLeft: "0.875rem",
                                marginRight: "0.875rem",
                                color: "#d32f2f",
                            }}
                        >
                            {error.message}
                        </Typography>
                    )}
                </Box>
            )}
        />
    );
};

export default CustomTextField;
