import { useQuery } from "@tanstack/react-query";

import { readBudgetById } from "../../services/budget";
import { TReadBudgetsResponse } from "../../services/budget/read-budgets";

type ReadBudgetByIdParams = {
    budgetId: string;
};

export function useReadBudgetById({ budgetId }: ReadBudgetByIdParams) {
    return useQuery<TReadBudgetsResponse, unknown, TReadBudgetsResponse>({
        enabled: true,
        queryKey: [`readBudgetById/${budgetId}`],
        queryFn: () => readBudgetById({ budgetId }),
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    });
}
