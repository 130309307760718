import { TBaseSVG } from "../../models";

export default ({
    className,
    color,
    onClick,
    title,
    height,
    width,
    viewBox,
}: TBaseSVG) => (
    <svg
        width={width ?? "35"}
        height={height ?? "35"}
        viewBox={viewBox ?? `0 0 35 35`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        className={className}
    >
        <title>{title}</title>
        <path
            d="M26.25 22.4291L17.5 17.5V7.75829L26.25 12.6729V22.4291ZM30.625 24.0625C30.625 24.6166 30.3188 25.0979 29.8521 25.3458L18.3313 31.8208C18.0979 31.9958 17.8063 32.0833 17.5 32.0833C17.1938 32.0833 16.9021 31.9958 16.6688 31.8208L5.14792 25.3458C4.91402 25.2231 4.71827 25.0385 4.58199 24.8122C4.4457 24.5859 4.37411 24.3266 4.37501 24.0625V10.9375C4.37501 10.3833 4.68126 9.90204 5.14792 9.65413L16.6688 3.17913C16.9021 3.00413 17.1938 2.91663 17.5 2.91663C17.8063 2.91663 18.0979 3.00413 18.3313 3.17913L29.8521 9.65413C30.3188 9.90204 30.625 10.3833 30.625 10.9375V24.0625ZM17.5 6.05204L7.29167 11.7979V23.202L17.5 28.9479L27.7083 23.202V11.7979L17.5 6.05204Z"
            fill={color ?? "black"}
            // fill-opacity="0.56"
        />
    </svg>
);
